import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function ZendeskGuide() {
  return (
    <Layout
      keywords="Zendesk installation guide, Zendesk IrisAgent documentation"
      title="Set up IrisAgent AI on your Zendesk account"
      description="Documentation on how to integrate IrisAgent with your Zendesk account to supercharge your support operations with AI."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/zd-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Zendesk Installation Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Zendesk Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisAgent Installation Guide for Zendesk</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent delivers proactive customer support with AI-powered alerting, automated resolution, and correlation of support tickets and product issues.

IrisAgent’s AI-powered support solution reduces support ticket resolution time by 45%, improves agent performance, and enhances communications between agents, customers, and internal technical teams to reduce escalations by 60%. It proactively discovers trending issues instead of waiting for customers to report them.
                    <br/>
                    <br/>
                    <h2>
                    Sign up on IrisAgent's Dashboard
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Go to the <a href="https://web.irisagent.com" target="_blank">IrisAgent dashboard</a> and sign in either GSuite or Microsoft SSO.
                    <img
                src="/img/docs-login.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Click on <b>Connect with Zendesk</b>. Give the OAuth permissions and click on Allow. This will automatically connect with both Zendesk tickets and Zendesk Guide.<br/>
                    <img
                src="/img/zd-connect.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    This step is optional. If you want to connect with more platforms, such as Jira, Confluence, PagerDuty, etc., navigate to <b>Manage Integrations</b> on the bottom left and connect with the relevant platforms.
                    </li>
                    </ol>
                    <br/>
                    <h2>
                    Install the IrisAgent app into your Zendesk account
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Navigate to <a href="https://www.zendesk.com/marketplace/apps/support/244660/irisagent/" target="_blank">IrisAgent's listing</a> on the Zendesk Marketplace.
                    </li>
                    <li>
                    Click on <b>Install</b> and follow the next steps.<br/>
                    <img
                src="/img/zd-ia-app.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    </ol>

<br/><br/>
{/*

                    <h2>
                    Details about IrisAgent's Zendesk integration
                    </h2>
                    <br/>
                    <ol>
                        <li>IrisAgent uses <code>read write</code> scopes for the OAuth integration with Zendesk.</li>
                        <li>Out of the box, IrisAgent does not perform any write actions to Zendesk and only reads Zendesk tickets and other data as new tickets come in.</li>
                        <li>IrisAgent explicitly de-duplicates requests sent to Zendesk to ensure that it doesn't exhaust the quota.</li>
                        <li>IrisAgent honors your Zendesk's account rate limits and does not make any unnecessary API calls to Zendesk. If in case, it gets a <code>429 Too Many Requests</code> status code, it backs off and retries as per <a href="https://www.ietf.org/archive/id/draft-polli-ratelimit-headers-02.html" target="_blank">the official RFC instructions</a>.</li>
                        <li>There are optional opt-in features of IrisAgent that leverage the <code>write</code> OAuth scope, such as writing automatic tags and adding private notes with AI insights to Zendesk tickets. Customers have to explicitly opt-in to these features. The write actions are performed safely, monitored via audit logs, and can be disabled easily from the UI. </li>
                    </ol>
<br/>
The above steps will complete the installation of IrisAgent. Please <a href="mailto:contact@irisagent.com" target="_blank">email us</a> once these steps are completed, and our team will start setting up the machine learning models for your account.
*/}
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
